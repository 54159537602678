exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-callback-js": () => import("./../../../src/pages/callback.js" /* webpackChunkName: "component---src-pages-callback-js" */),
  "component---src-pages-dashboard-local-dev-js": () => import("./../../../src/pages/dashboard/localDev.js" /* webpackChunkName: "component---src-pages-dashboard-local-dev-js" */),
  "component---src-pages-dashboard-pmo-js": () => import("./../../../src/pages/dashboard/pmo.js" /* webpackChunkName: "component---src-pages-dashboard-pmo-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-project-details-index-js": () => import("./../../../src/pages/project-details/index.js" /* webpackChunkName: "component---src-pages-project-details-index-js" */),
  "component---src-pages-project-details-pmo-index-js": () => import("./../../../src/pages/project-details/pmo/index.js" /* webpackChunkName: "component---src-pages-project-details-pmo-index-js" */),
  "component---src-pages-project-list-index-js": () => import("./../../../src/pages/projectList/index.js" /* webpackChunkName: "component---src-pages-project-list-index-js" */),
  "component---src-pages-project-list-local-dev-js": () => import("./../../../src/pages/projectList/localDev.js" /* webpackChunkName: "component---src-pages-project-list-local-dev-js" */),
  "component---src-pages-resources-details-js": () => import("./../../../src/pages/resources/details.js" /* webpackChunkName: "component---src-pages-resources-details-js" */),
  "component---src-pages-resources-index-js": () => import("./../../../src/pages/resources/index.js" /* webpackChunkName: "component---src-pages-resources-index-js" */),
  "component---src-pages-roadmap-js": () => import("./../../../src/pages/roadmap.js" /* webpackChunkName: "component---src-pages-roadmap-js" */),
  "component---src-pages-silent-renew-js": () => import("./../../../src/pages/silent-renew.js" /* webpackChunkName: "component---src-pages-silent-renew-js" */),
  "component---src-pages-teams-local-dev-js": () => import("./../../../src/pages/teams/local-dev.js" /* webpackChunkName: "component---src-pages-teams-local-dev-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */)
}

